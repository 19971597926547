<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col
            ><h4>Capacité d'achat à {{ item.nom_iris }}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col col md="12">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <td class="tabheader" scope="col">
                            M&eacute;diane capacit&eacute; d'achat (25ans)
                        </td>
                        <td class="tabheader" scope="col">
                            Moyenne capacit&eacute; d'achat m<span class="exposant">2</span>
                            (25ans)<br/>pour 55 m<span class="exposant">2</span>
                        </td>
                        <td class="tabheader" scope="col">
                            M&eacute;diane capacit&eacute; d'achat (25ans)<br/>30% plus
                            riche
                        </td>
                        <td class="tabheader" scope="col">
                            Moyenne capacit&eacute; d'achat m<span class="exposant">2</span>
                            (25ans)<br/>30% plus riche - pour 55 m<span class="exposant"
                        >2</span
                        >
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td align="center">
                            {{
                                verify_null_currency(item.selected_cap_achat[0].cap_achat_25a)
                            }}
                        </td>
                        <td align="center">
                            {{
                                verify_null_currency(
                                  item.selected_cap_achat[0].cap_achat_25a_moy
                                )
                            }}
                        </td>
                        <td align="center">
                            {{
                                verify_null_currency(
                                  item.selected_cap_achat[0].cap_achat_25a_riche
                                )
                            }}
                        </td>
                        <td align="center">
                            {{
                                verify_null_currency(
                                  item.selected_cap_achat[0].cap_achat_25a_riche_moy
                                )
                            }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p><b>Hypothèses de capacité d’emprunt hors apport 3.75% TAEG, 33% d’endettement</b></p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {verify_null_currency} from './functions_utilities';

export default {
    name: 'Cap_achat',
    data()
    {
        return {};
    },
    methods: {
        verify_null_currency
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'})
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}
</style>
