<template>
    <b-container>
        <b-row id="titre">
            <b-col col md="12">
                <h3 class="text-left pl-2">Simulateur de mensualit&eacute;s</h3>
                <b-row>
                    <b-col col md="6">
                        <div class="m-2">
                            <h4>Type d'achat</h4>
                            <b-form-select
                              v-model="typo"
                              :options="typo_values"
                            ></b-form-select>
                        </div>
                        <div class="m-2">
                            <h4>Durée d'emprunt</h4>
                            <vue-slider
                              style="padding:25px;"
                              v-model="duree"
                              :marks="duree_marks"
                              :min="10"
                              :max="25"
                              :included="true"
                            ></vue-slider>
                        </div>
                    </b-col>
                    <b-col col md="6">
                        <div class="m-2">
                            <h4>Montant de l'achat</h4>
                            <b-form-input
                              id="montant"
                              v-model="montant"
                              type="number"
                            ></b-form-input>
                        </div>
                        <div class="m-2">
                            <h4>Apport personnel</h4>
                            <b-form-input
                              id="apport"
                              v-model="apport"
                              type="number"
                            ></b-form-input>
                        </div>
                        <div class="m-2">
                            <h4>Coût des travaux</h4>
                            <b-form-input
                              id="travaux"
                              v-model="travaux"
                              type="number"
                            ></b-form-input>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-left" col md="12">
                        Montant total à financer :
                        <strong style="font-size:14px;"
                        >{{ verify_null_currency(emprunt) }} </strong
                        ><br/>
                        Dont frais de notaire :
                        <strong style="font-size:14px;"
                        >{{ verify_null_currency(frais_de_notaire) }} </strong
                        ><br/>
                        Durée d'emprunt :
                        <strong style="font-size:14px;">{{ duree }} ans </strong><br/>
                        TAEG : <strong style="font-size:14px;">{{ taeg[duree] }} % </strong
                    ><br/>
                        Mensualités :
                        <strong style="font-size:20px;"
                        >{{ verify_null_currency(mensualites) }} </strong
                        ><br/>
                    </b-col>
                </b-row>

                <hr/>
                <div class="text-left">
                    <h4>Informations d'usage</h4>
                    <span
                    >Cette estimation a une vocation purement informative, elle ne
            refl&egrave;te pas n&eacute;cessairement la r&eacute;alit&eacute;,
            ne constitue en aucun cas un conseil financier et ne revêt aucun
            caractère contractuel.</span
                    >
                </div>
                <br/><br/>

                <span style="style:italic;color:#666;"
                >G&eacute;n&eacute;r&eacute; depuis l'application EDII by Inovefa
          (https://inovefa-edii.com)</span
                >
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import {mapGetters} from 'vuex';
import * as tools from './functions_utilities';

export default {
    name: 'Mensualite',
    components: {
        VueSlider
    },
    data()
    {
        return {
            verify_null_currency: tools.verify_null_currency,
            typo: 'ancien',
            montant: 250000,
            apport: 20000,
            travaux: 50000,
            typo_values: [
                {value: 'ancien', text: "Achat dans l'ancien"},
                {value: 'neuf', text: 'Achat dans le neuf'}
            ],
            taeg: {
                '10': 3,
                '15': 3.25,
                '20': 3.5,
                '25': 3.75
            },
            nb_mois: {
                '10': 120,
                '15': 180,
                '20': 240,
                '25': 300
            },
            duree: '10',
            duree_marks: {
                '10': '10 ans',
                '15': '15 ans',
                '20': '20 ans',
                '25': '25 ans'
            }
        };
    },
    computed: {
        frais_de_notaire()
        {
            return this.typo === 'ancien'
              ? parseInt(this.montant) * 0.08
              : parseInt(this.montant) * 0.025;
        },
        emprunt()
        {
            return (
              parseInt(this.montant) +
              this.frais_de_notaire -
              parseInt(this.apport) +
              parseInt(this.travaux)
            );
        },
        mensualites()
        {
            return (
              this.VPM(
                this.taeg[this.duree] / 100 / 12,
                this.nb_mois[this.duree],
                this.emprunt
              ) * -1
            );
        }
    },
    methods: {
        VPM(tx, npm, va, vc = 0, typeRembt = 0)
        {
            if (
              typeof tx != 'number' ||
              typeof npm != 'number' ||
              typeof va != 'number' ||
              typeof vc != 'number' ||
              typeof typeRembt != 'number'
            )
                return false;

            if (typeRembt > 1 || typeRembt < 0) return false;

            var txActuariel = Math.pow(1 + tx, -npm);

            if (1 - txActuariel === 0) return 0;

            var vpm =
              ((va + vc * txActuariel) * tx) /
              (1 - txActuariel) /
              (1 + tx * typeRembt);
            return -vpm;
        }
    }
};
</script>

<style lang="scss">
#titre {
    h3 {
        background-color: #58a4b0;
        color: #fff;
    }

    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.qualite {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: justify;
    }
}

.potentiel {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: left;
    }
}
</style>
