<template>
	<div>
		<v-list-item class="m-0">
			<p class="mb-0 mt-2" style="text-align:left;font-family:Gotham;">
				Année de transaction
			</p>
		</v-list-item>
		<v-list-item class="m-0">
			<v-divider></v-divider>
		</v-list-item>
		<v-list-item>
			<b-form-checkbox-group
				style="text-align: left;"
				id="btnradios3"
				stacked
				button-variant="outline-secondary"
				v-model="getFilter"
				:options="keyOptions"
				name="radioBtnStacked"
			/>
		</v-list-item>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	name: 'FiltreTransactionsGeolocalisees',
	data()
	{
		return {
			keyOptions: [
                {text: '2024', value: '2024'},
                {text: '2023', value: '2023'},
				{text: '2022', value: '2022'},
				{text: '2021', value: '2021'},
				{text: '2020', value: '2020'},
				{text: '2019', value: '2019'}
			],
			filter_selected: ['2024', '2023']
		};
	},
	mounted()
	{
		if (this.saved_filter.length === 0)
		{
			this.$store.commit('mutateFilter', this.filter_selected);
		}
	},
	created()
	{
	},
	watch: {
		filter_selected(newValue, oldValue)
		{
			this.$store.commit('mutateFilter', newValue);
		}
	},
	computed: {
		...mapGetters({saved_filter: 'getFilter'}),
		getFilter: {
			get()
			{
				return this.saved_filter;
			},
			set(newSaved_filter)
			{
				this.filter_selected = newSaved_filter;
			}
		}
	}
};
</script>

<style></style>
