<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="4">
                <h4>SHAB moyenne du projet (m²)</h4>
                <v-text-field v-model="shab" filled type="number"></v-text-field>
                <h4>Prix moyen au m² (€)</h4>
                <v-text-field v-model="prix_moyen" filled type="number"></v-text-field>
                <h4>Apport envisagé (%)</h4>
                <v-text-field
                  v-model="apport"
                  filled
                  type="number"
                  :rules="rules"
                ></v-text-field>

                <br/>
                <div style="text-align:center;">
                    Montant moyen à financer : <br/>
                    <strong style="font-size:25px;"
                    >{{ verify_null_currency(montant_moyen) }} </strong
                    ><br/><br/>
                    Sur ce projet, en fonction des informations communiquées, la
                    fourchette de ménages disposant de la capacité d'achat nécessaire à
                    financer l'acquisition est de :<br/>
                    <strong style="font-size:25px;">{{ percent }}</strong
                    ><br/>
                </div>
            </b-col>
            <b-col col md="8">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <td class="tabheader"></td>
                        <td class="tabheader">
                            Revenus fiscaux déclarés par les ménages en euros ({{
                                item.nom_com
                            }}) - {{ item.selected_menage_solvable[0].annee }}
                        </td>
                        <td class="tabheader">
                            Capacité de remboursement mensuel
                        </td>
                        <td class="tabheader">Capacité d'emprunt sur 20 ans<br>(TAEG 3.5%)</td>
                        <td class="tabheader">Capacité d'emprunt sur 25 ans<br>(TAEG 3.75%)</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="tabheader" scope="col">1er décile</td>
                        <td>
                            {{
                                verify_null_currency(item.selected_menage_solvable[0].dec1)
                            }}
                        </td>
                        <td>
                            {{
                                verify_null_currency(
                                  cap_remb_mensu(item.selected_menage_solvable[0].dec1)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_20a(item.selected_menage_solvable[0].dec1) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_20a(item.selected_menage_solvable[0].dec1)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_25a(item.selected_menage_solvable[0].dec1) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_25a(item.selected_menage_solvable[0].dec1)
                                )
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tabheader" scope="col">2nd décile</td>
                        <td>
                            {{
                                verify_null_currency(item.selected_menage_solvable[0].dec2)
                            }}
                        </td>
                        <td>
                            {{
                                verify_null_currency(
                                  cap_remb_mensu(item.selected_menage_solvable[0].dec2)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_20a(item.selected_menage_solvable[0].dec2) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_20a(item.selected_menage_solvable[0].dec2)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_25a(item.selected_menage_solvable[0].dec2) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_25a(item.selected_menage_solvable[0].dec2)
                                )
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tabheader" scope="col">3ème décile</td>
                        <td>
                            {{
                                verify_null_currency(item.selected_menage_solvable[0].dec3)
                            }}
                        </td>
                        <td>
                            {{
                                verify_null_currency(
                                  cap_remb_mensu(item.selected_menage_solvable[0].dec3)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_20a(item.selected_menage_solvable[0].dec3) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_20a(item.selected_menage_solvable[0].dec3)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_25a(item.selected_menage_solvable[0].dec3) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_25a(item.selected_menage_solvable[0].dec3)
                                )
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tabheader" scope="col">4ème décile</td>
                        <td>
                            {{
                                verify_null_currency(item.selected_menage_solvable[0].dec4)
                            }}
                        </td>
                        <td>
                            {{
                                verify_null_currency(
                                  cap_remb_mensu(item.selected_menage_solvable[0].dec4)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_20a(item.selected_menage_solvable[0].dec4) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_20a(item.selected_menage_solvable[0].dec4)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_25a(item.selected_menage_solvable[0].dec4) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_25a(item.selected_menage_solvable[0].dec4)
                                )
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tabheader" scope="col">Médiane</td>
                        <td>
                            <strong>{{
                                    verify_null_currency(item.selected_menage_solvable[0].med)
                                }}</strong>
                        </td>
                        <td>
                            {{
                                verify_null_currency(
                                  cap_remb_mensu(item.selected_menage_solvable[0].med)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_20a(item.selected_menage_solvable[0].med) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_20a(item.selected_menage_solvable[0].med)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_25a(item.selected_menage_solvable[0].med) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_25a(item.selected_menage_solvable[0].med)
                                )
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tabheader" scope="col">6ème décile</td>
                        <td>
                            {{
                                verify_null_currency(item.selected_menage_solvable[0].dec6)
                            }}
                        </td>
                        <td>
                            {{
                                verify_null_currency(
                                  cap_remb_mensu(item.selected_menage_solvable[0].dec6)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_20a(item.selected_menage_solvable[0].dec6) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_20a(item.selected_menage_solvable[0].dec6)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_25a(item.selected_menage_solvable[0].dec6) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_25a(item.selected_menage_solvable[0].dec6)
                                )
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tabheader" scope="col">7ème décile</td>
                        <td>
                            {{
                                verify_null_currency(item.selected_menage_solvable[0].dec7)
                            }}
                        </td>
                        <td>
                            {{
                                verify_null_currency(
                                  cap_remb_mensu(item.selected_menage_solvable[0].dec7)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_20a(item.selected_menage_solvable[0].dec7) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_20a(item.selected_menage_solvable[0].dec7)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_25a(item.selected_menage_solvable[0].dec7) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_25a(item.selected_menage_solvable[0].dec7)
                                )
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tabheader" scope="col">8ème décile</td>
                        <td>
                            {{
                                verify_null_currency(item.selected_menage_solvable[0].dec8)
                            }}
                        </td>
                        <td>
                            {{
                                verify_null_currency(
                                  cap_remb_mensu(item.selected_menage_solvable[0].dec8)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_20a(item.selected_menage_solvable[0].dec8) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_20a(item.selected_menage_solvable[0].dec8)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_25a(item.selected_menage_solvable[0].dec8) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_25a(item.selected_menage_solvable[0].dec8)
                                )
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tabheader" scope="col">9ème décile</td>
                        <td>
                            {{
                                verify_null_currency(item.selected_menage_solvable[0].dec9)
                            }}
                        </td>
                        <td>
                            {{
                                verify_null_currency(
                                  cap_remb_mensu(item.selected_menage_solvable[0].dec9)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_20a(item.selected_menage_solvable[0].dec9) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_20a(item.selected_menage_solvable[0].dec9)
                                )
                            }}
                        </td>
                        <td
                          :class="{
                  green:
                    cap_25a(item.selected_menage_solvable[0].dec9) >=
                    montant_moyen
                }"
                        >
                            {{
                                verify_null_currency(
                                  cap_25a(item.selected_menage_solvable[0].dec9)
                                )
                            }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
        <b-row></b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import * as tools from './functions_utilities';

export default {
    name: 'MenageSolvable',
    data()
    {
        return {
            shab: 55,
            prix_moyen: 4750,
            apport: 20,
            rules: [v => v <= 100 || 'Maximum 100%', v => v >= 0 || 'Minimum 0%']
        };
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
        montant_moyen()
        {
            return this.prix_moyen * this.shab * (1 - this.limit_apport / 100);
        },
        limit_apport()
        {
            if (this.apport > 100)
                return 100;
            else if (this.apport < 0)
                return 0;
            else
                return this.apport;
        },
        percent()
        {
            if (
              this.item.selected_menage_solvable[0].dec1 !== 0 &&
              this.cap_25a(this.item.selected_menage_solvable[0].dec1) >=
              this.montant_moyen
            )
            {
                return '80 à 90%';
            }
            if (
              this.item.selected_menage_solvable[0].dec2 !== 0 &&
              this.cap_25a(this.item.selected_menage_solvable[0].dec2) >=
              this.montant_moyen
            )
            {
                return '70 à 80%';
            }
            if (
              this.item.selected_menage_solvable[0].dec3 !== 0 &&
              this.cap_25a(this.item.selected_menage_solvable[0].dec3) >=
              this.montant_moyen
            )
            {
                return '60 à 70%';
            }
            if (
              this.item.selected_menage_solvable[0].dec4 !== 0 &&
              this.cap_25a(this.item.selected_menage_solvable[0].dec4) >=
              this.montant_moyen
            )
            {
                return '50 à 60%';
            }
            if (
              this.item.selected_menage_solvable[0].med !== 0 &&
              this.cap_25a(this.item.selected_menage_solvable[0].med) >=
              this.montant_moyen
            )
            {
                return '40 à 50%';
            }
            if (
              this.item.selected_menage_solvable[0].dec6 !== 0 &&
              this.cap_25a(this.item.selected_menage_solvable[0].dec6) >=
              this.montant_moyen
            )
            {
                return '30 à 40%';
            }
            if (
              this.item.selected_menage_solvable[0].dec7 !== 0 &&
              this.cap_25a(this.item.selected_menage_solvable[0].dec7) >=
              this.montant_moyen
            )
            {
                return '20 à 30%';
            }
            if (
              this.item.selected_menage_solvable[0].dec8 !== 0 &&
              this.cap_25a(this.item.selected_menage_solvable[0].dec8) >=
              this.montant_moyen
            )
            {
                return '10 à 20%';
            }
            if (
              this.item.selected_menage_solvable[0].dec9 !== 0 &&
              this.cap_25a(this.item.selected_menage_solvable[0].dec9) >=
              this.montant_moyen
            )
            {
                return 'Moins de 10%';
            }

            return "Pas d'information disponible.";
        }
    },
    methods: {
        verify_null_currency: tools.verify_null_currency,
        cap_remb_mensu(dec)
        {
            return dec / 12.0 / 3.33;
        },
        cap_20a(dec)
        {
            return this.VA(3.5 / 12.0, 20 * 12, this.cap_remb_mensu(dec), 0, 100);
        },
        cap_25a(dec)
        {
            return this.VA(3.75 / 12.0, 25 * 12, this.cap_remb_mensu(dec), 0, 100);
        },
        VA(taux, npm, flux, start = 0, arrondi = 100)
        {
            let somme = start;

            for (let i = 0; i < npm; i++)
            {
                somme += flux / Math.pow(1.0 + taux / 100.0, i + 1);
            }

            if (arrondi)
                return somme % arrondi >= arrondi / 2
                  ? somme + arrondi - (somme % arrondi)
                  : somme - (somme % arrondi);
            return somme;
        }
    }
};
</script>

<style lang="scss">
.green {
    background-color: #4caf50;
}

#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}
</style>
